import { HodorError } from './HodorError';

/**
 * A custom `HodorMissingPassTokenError` is thrown when a statusCode 401 is received.\
 * Usually when tokenPass or XX-Profile-Id headers are missing.
 */
export class HodorMissingPassTokenError extends HodorError {
  constructor(statusCode: 401) {
    super(statusCode);

    Object.setPrototypeOf(this, HodorMissingPassTokenError.prototype);

    this.name = 'HodorMissingPassTokenError';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HodorMissingPassTokenError);
    }
  }
}
