import { fetch } from '@canalplus/mycanal-fetch';
import { Service, type LogApiMetadata } from '@canalplus/mycanal-logger';
import type { PassSdkConfig } from '../../config/types';
import { PassError } from '../../errors/PassError';
import { logError } from './logError';

type FetchPassParameters = {
  /** Api name */
  api: string;

  passSdkConfig: PassSdkConfig;

  /** Route name */
  route: string;

  /** Url to fetch */
  url: string;
};

/**
 * Helper in charge of:
 * - Fetching the given url (+ log info)
 * - Throwing an error if the response is not ok (+ log error)
 *
 * @private
 */
export const fetchPass = async ({
  api,
  passSdkConfig,
  route,
  url,
}: FetchPassParameters): Promise<Response> => {
  const { offerLocation, offerZone, fetchOptions, logger, logMetadata } =
    passSdkConfig;

  const baseLogs: LogApiMetadata = {
    ...(logMetadata && logMetadata),
    endpoint: url,
    offerLocation,
    offerZone,
    route,
    startAt: Date.now(),
  } as const;

  try {
    const response = await fetch(url, {
      ...fetchOptions,
    });

    logger.info(
      `PASS ${api} ${response.status}`,
      logger.generateApiMetadata(Service.Pass, api, {
        ...baseLogs,
        statusCode: response.status,
      })
    );

    if (!response.ok) {
      throw new PassError(`PASS ${api} Error while fetching`, response.status);
    }

    return response;
  } catch (error) {
    logError({ error, logger, baseLogs, api });

    throw error;
  }
};
