import { isSomeEnum } from '@canalplus/mycanal-commons';

/** Brand operator */
export const Operator = {
  CanalPlus: 'cp',
  CanalBox: 'cb',
  Tim: 'ti',
} as const;

export type Operator = (typeof Operator)[keyof typeof Operator];

/** {@link @canalplus/sdk-core!Operator} type guard */
export const isOperator = isSomeEnum(Operator);

/** Name of an `Operator` */
export const OperatorName = {
  CanalBox: 'CANALBOX',
  CanalPlus: 'CANAL+',
  Tim: 'TIMVISION',
} as const;

export type OperatorName = (typeof OperatorName)[keyof typeof OperatorName];

/** {@link @canalplus/sdk-core!OperatorName} type guard */
export const isOperatorName = isSomeEnum(OperatorName);

/** Mapping object of `OperatorName` by `Operator` */
export const operatorNameMapping: Record<Operator, OperatorName> = {
  [Operator.CanalBox]: OperatorName.CanalBox,
  [Operator.CanalPlus]: OperatorName.CanalPlus,
  [Operator.Tim]: OperatorName.Tim,
};

/** Url of an `Operator` */
export const OperatorUrl = {
  CanalBox: 'canalbox.com',
  CanalPlus: 'canalpus.com',
  Tim: 'timvision.it',
} as const;

export type OperatorUrl = (typeof OperatorUrl)[keyof typeof OperatorUrl];

/** {@link @canalplus/sdk-core!OperatorUrl} type guard */
export const isOperatorUrl = isSomeEnum(OperatorUrl);

/** Mapping object of `OperatorUrl` by `Operator` */
export const operatorUrlMapping: Record<Operator, OperatorUrl> = {
  [Operator.CanalBox]: OperatorUrl.CanalBox,
  [Operator.CanalPlus]: OperatorUrl.CanalPlus,
  [Operator.Tim]: OperatorUrl.Tim,
};
