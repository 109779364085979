import { ApiV2Authenticate } from '@dce-front/hodor-types';
import { HodorApiParameter, HodorSdkConfig } from '../config/types';
import { fetchHodor } from '../helpers/fetchHodor';
import { getBooleanToNumber } from '../helpers/getBooleanToNumber';
import { getUrlWithQueries } from '../helpers/getUrlWithQueries';

/** ⚠️ Hodor authenticate should not spread any Drogon parameters. */
export const HODOR_API_PARAMETERS: HodorApiParameter[] = [
  {
    id: 'language',
    in: 'parameters',
  },
  {
    id: 'macros',
    in: 'parameters',
  },
  {
    id: 'micros',
    in: 'parameters',
  },
  {
    id: 'offerLocation',
    in: 'parameters',
  },
  {
    id: 'offerZone',
    in: 'parameters',
  },
];

const API = 'authenticate';

export type AuthenticateParameters = {
  abTestingPopulation?: number;
  extraInfo?: string;
  hardware?: string;
  isActivated?: boolean;
  isAuthenticated?: boolean;
  isBusinessProfile?: boolean;
  isHighResolution?: boolean;
  isInAllowedGeoZone?: boolean;
  isKidsProfile?: boolean;
  paired?: number;
  pdsdevice?: string;
  pdsHybrids?: string;
  pdsNormal?: string;
  preview?: number;

  /** Whether the user allows to collect his personal data */
  shouldCollectUserData?: boolean;
};

const getParametersForApi = ({
  isActivated,
  isAuthenticated,
  isBusinessProfile,
  isHighResolution,
  isInAllowedGeoZone,
  isKidsProfile,
  shouldCollectUserData,
  ...restAuthenticateParameters
}: Omit<AuthenticateParameters, 'url'>) => ({
  ...restAuthenticateParameters,
  ...(isActivated !== undefined && {
    isActivated: getBooleanToNumber(isActivated),
  }),
  ...(isAuthenticated !== undefined && {
    isAuthenticated: getBooleanToNumber(isAuthenticated),
  }),
  ...(isBusinessProfile !== undefined && {
    isBusinessProfile: getBooleanToNumber(isBusinessProfile),
  }),
  ...(isHighResolution !== undefined && {
    highresolution: getBooleanToNumber(isHighResolution),
  }),
  ...(isInAllowedGeoZone !== undefined && {
    isInAllowedGeoZone: getBooleanToNumber(isInAllowedGeoZone),
  }),
  ...(isKidsProfile !== undefined && {
    isKidsProfile: getBooleanToNumber(isKidsProfile),
  }),
  ...(shouldCollectUserData !== undefined && {
    collectUserData: getBooleanToNumber(shouldCollectUserData),
  }),
});

/** Fetch Hodor authenticate Api */
export const authenticate = async (
  hodorSdkConfig: HodorSdkConfig,
  authenticateParameters: AuthenticateParameters
): Promise<ApiV2Authenticate> => {
  const { logger, urls: { authenticate: url } = {} } = hodorSdkConfig;

  if (!url) {
    throw new Error(`HODOR ${API} Url is not provided`);
  }

  const startAt = Date.now();

  const response = await fetchHodor({
    api: API,
    hodorSdkConfig,
    hodorSdkParameters: HODOR_API_PARAMETERS,
    startAt,
    url: getUrlWithQueries(url, getParametersForApi(authenticateParameters)),
  });

  const jsonResponse = await response.json();

  logger.debug(`HODOR ${API} Response:`, jsonResponse);

  return jsonResponse;
};
