import { Lang } from '../lang';
import {
  CanalPlusOfferLocation,
  CanalBoxOfferLocation,
  TimVisionOfferLocation,
  OfferLocation,
  isCanalPlusOfferLocation,
  isCanalBoxOfferLocation,
  isTimVisionOfferLocation,
} from '../offerLocation';
import {
  CanalPlusOfferZone,
  CanalBoxOfferZone,
  TimVisionOfferZone,
  OfferZone,
} from '../offerZone';
import {
  canalPlusOfferZoneList,
  canalBoxOfferZoneList,
  timVisionOfferZoneList,
} from '../offerZonesList';
import { Operator } from '../operator';

type GetOfferZoneParametersGeneric = {
  operator: Operator;
  offerLocation: OfferLocation;
  lang?: Lang;
};

type GetOfferZoneParametersCanalPlus = {
  operator: Extract<Operator, 'cp'>;
  offerLocation: CanalPlusOfferLocation;
  lang?: Lang;
};

type GetOfferZoneParametersCanalBox = {
  operator: Extract<Operator, 'cb'>;
  offerLocation: CanalBoxOfferLocation;
  lang?: Lang;
};

type GetOfferZoneParametersTimVision = {
  operator: Extract<Operator, 'ti'>;
  offerLocation: TimVisionOfferLocation;
  lang?: Lang;
};

type GetOfferZoneParameters =
  | GetOfferZoneParametersGeneric
  | GetOfferZoneParametersCanalPlus
  | GetOfferZoneParametersCanalBox
  | GetOfferZoneParametersTimVision;

/**
 * Get the correct `OfferZone` for a given:
 * - `Operator`
 * - `OfferLocation`
 * - `Lang`
 *
 * Throws an error if the `OfferLocation` is not supported for the given `Operator`
 */
export function getOfferZone({
  operator,
  offerLocation,
  lang,
}: GetOfferZoneParametersCanalPlus): CanalPlusOfferZone;
export function getOfferZone({
  operator,
  offerLocation,
  lang,
}: GetOfferZoneParametersCanalBox): CanalBoxOfferZone;
export function getOfferZone({
  operator,
  offerLocation,
  lang,
}: GetOfferZoneParametersTimVision): TimVisionOfferZone;
export function getOfferZone({
  operator,
  offerLocation,
  lang,
}: GetOfferZoneParametersGeneric): OfferZone;

export function getOfferZone({
  operator,
  offerLocation,
  lang,
}: GetOfferZoneParameters):
  | OfferZone
  | CanalPlusOfferZone
  | CanalBoxOfferZone
  | TimVisionOfferZone {
  if (
    operator === Operator.CanalPlus &&
    isCanalPlusOfferLocation(offerLocation)
  ) {
    return (
      (lang && canalPlusOfferZoneList[offerLocation][lang]) ||
      canalPlusOfferZoneList[offerLocation].default
    );
  }

  if (
    operator === Operator.CanalBox &&
    isCanalBoxOfferLocation(offerLocation)
  ) {
    return (
      (lang && canalBoxOfferZoneList[offerLocation][lang]) ||
      canalBoxOfferZoneList[offerLocation].default
    );
  }

  if (operator === Operator.Tim && isTimVisionOfferLocation(offerLocation)) {
    return (
      (lang && timVisionOfferZoneList[offerLocation][lang]) ||
      timVisionOfferZoneList[offerLocation].default
    );
  }

  throw new Error(
    `OfferLocation ${offerLocation} is not supported for operator ${operator} and lang ${lang}`
  );
}
