import type { HodorApiConfig, HodorSdkConfig } from '../config/types';

export const getUserId = ({
  analyticsId,
  anonymousId,
  isAnalyticsEnabled,
  isAnonymousAnalyticsEnabled,
}: Pick<
  HodorSdkConfig,
  | 'analyticsId'
  | 'anonymousId'
  | 'isAnalyticsEnabled'
  | 'isAnonymousAnalyticsEnabled'
>): string | undefined => {
  if (isAnalyticsEnabled) {
    return analyticsId;
  }

  if (isAnonymousAnalyticsEnabled) {
    return anonymousId;
  }

  return;
};

/** Map the HodorSdk config object to match the HodorApi config keys */
export const getHodorApiConfig = ({
  acceptLanguage,
  analyticsId,
  anonymousId,
  appKey,
  arboVersion,
  cmsToken,
  crmSegments,
  device,
  deviceId,
  experiments,
  featureToggles,
  isAnalyticsEnabled,
  isAnonymousAnalyticsEnabled,
  like,
  macros,
  micros,
  offerLocation,
  offerZone,
  passToken,
  profileId,
  requestId,
  segments,
}: HodorSdkConfig): HodorApiConfig => ({
  'accept-language': acceptLanguage,
  'xx-profile-id': profileId,
  appKey,
  arboVersion,
  cmsToken,
  collectUserData:
    isAnalyticsEnabled || isAnonymousAnalyticsEnabled ? '1' : '0',
  crmSegments,
  device,
  deviceId,
  experiments,
  featureToggles,
  language: acceptLanguage,
  like,
  macros,
  micros,
  offerLocation,
  offerZone,
  requestId,
  segments,
  tokenPass: passToken,
  userId: getUserId({
    analyticsId,
    anonymousId,
    isAnalyticsEnabled,
    isAnonymousAnalyticsEnabled,
  }),
});
