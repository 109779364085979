import Cookies from 'js-cookie';
import { getRootDomain } from '../url/getRootDomain';

/**
 * Cookie Helper
 * --------------------
 *
 * Create a cookie
 *
 * @param name The **name** of the cookie
 * @param value The **value** of the cookie. Object will be stringified
 * @param cookieOptions Custom **cookie options** that will be merged with the default ones
 *
 * Default cookie options are:
 *  - **domain**: The root domain
 *  - **expires**: 365 days
 *  - **sameSite**: 'none'
 *  - **secure**: true
 *
 * @category Cookie
 */
export const setCookie = (
  name: string,
  value: string | object,
  cookieOptions?: Cookies.CookieAttributes
): void => {
  const stringValue = typeof value === 'object' ? JSON.stringify(value) : value;

  const defaultCookieOptions: Cookies.CookieAttributes = {
    domain: getRootDomain(window.location.href),
    expires: 365,
    sameSite: 'none',
    secure: true,
  };

  Cookies.set(name, stringValue, {
    ...defaultCookieOptions,
    ...cookieOptions,
  });
};
