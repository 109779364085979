import { isClientSide } from '../window/isClientSide';

declare global {
  interface Window {
    consentCallbackQueue?: any[];
    eventQueue?: any[];
    trackingQueue?: any[];
    sendEvent?: (T: any) => void;
    sendPageView?: (T: any) => void;
    waitForUserConsent?: (T: (args: Record<string, any>) => void) => void;
  }
}

/**
 * Tracking Helper
 * --------------------
 *
 * Initialize all settings for Omniture tracking scripts
 *
 * @category Tracking
 */
export const initTracking = (): void => {
  if (!isClientSide()) {
    return;
  }

  /* Tracking queue */
  window.trackingQueue = window.trackingQueue || [];
  window.sendPageView = (trackingObject) => {
    window.trackingQueue?.push(trackingObject);
  };

  /* Event queue */
  window.eventQueue = window.eventQueue || [];
  window.sendEvent = (event) => {
    window.eventQueue?.push(event);
  };

  /* Consent queue */
  window.consentCallbackQueue = window.consentCallbackQueue || [];
  window.waitForUserConsent = (callback) => {
    window.consentCallbackQueue?.push(callback);
  };
};
