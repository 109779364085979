import { ApiV2Authenticate } from '@dce-front/hodor-types';
import { HodorSdkConfig } from '../config/types';

/**
 * Merge the following values from `ApiV2InitResponse` in the `HodorSdkConfig`:
 * - **token**
 * - **baseLogs.userStatus**
 */
export const getMergedConfigWithAuthenticate = (
  hodorSdkConfig: HodorSdkConfig,
  { token, settings }: ApiV2Authenticate
): HodorSdkConfig => ({
  ...hodorSdkConfig,
  cmsToken: token,
  // TODO: lot-3 move that in mycanal ?
  logMetadata: {
    ...(hodorSdkConfig.logMetadata && hodorSdkConfig.logMetadata),
    userStatus: settings?.userStatus,
  },
});
