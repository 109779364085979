/**
 * @description This function is used to get the deviceId and trackingKey from the deviceId cookie
 * @param {string} deviceIdCookie - The deviceId cookie
 * @returns {Object} - An object containing the deviceId and trackingKey
 */
export const getUserDeviceKeys = (
  deviceIdCookie: string | undefined
): { deviceId?: string; trackingKey?: string } => {
  return {
    deviceId: deviceIdCookie?.split(':')[0],
    trackingKey: deviceIdCookie?.split(':')[2],
  };
};
