import type { PassErrorCode } from './PassErrorCode';

/** A custom `PassError` is thrown when something goes wrong fetching Pass */
export class PassError extends Error {
  constructor(message: string, statusCode: number, errorCode?: PassErrorCode) {
    super();

    Object.setPrototypeOf(this, PassError.prototype);

    this.name = 'PassError';
    this.message = message;
    this.statusCode = statusCode;
    this.errorCode = errorCode;

    /* istanbul ignore if -- @preserve */
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PassError);
    }
  }

  /** The HTTP response status code */
  statusCode: number;

  /** The ErrorCode returned by Pass */
  errorCode?: PassErrorCode;
}
