const getMessage = (statusCode: number): string => {
  switch (statusCode) {
    case 401:
      return 'tokenPass or XX-Profile-Id headers are missing.';

    case 403:
      return 'passToken is expired or invalid.';

    case 404:
      return 'Not found.';

    default:
      return 'An error has occurred.';
  }
};

/**
 * A custom `HodorError` is thrown when Hodor returns an invalid status code
 *
 * 5xx status codes are not handled.
 */
export class HodorError extends Error {
  constructor(statusCode: number) {
    super();

    Object.setPrototypeOf(this, HodorError.prototype);

    this.message = getMessage(statusCode);
    this.name = 'HodorError';
    this.statusCode = statusCode;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HodorError);
    }
  }

  statusCode: number;
}
