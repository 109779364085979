/**
 * Parse a **passId** to determine its type
 * - pass example: FR=AAAAELsH6mxkfN6VxFZGvDNwR24qnNf...
 * - okta example: 20111q26Ry8oR0hQcL4WuMwJosCno...
 *
 * Fallback to `pass` if the type cannot be determined
 */
export const getPassIdType = (passId: string): 'pass' | 'okta' => {
  if (passId[2] === '=') {
    return 'pass';
  }

  if (passId.match(/^\d{4}/)) {
    return 'okta';
  }

  return 'pass';
};
