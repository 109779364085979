import {
  PassError,
  createToken,
  type CreateTokenParameters,
  type CreateTokenResponse,
} from '@canalplus/sdk-pass';
import { getPassId } from '../../helpers/pass/getPassId';
import { getPassSdkConfig } from '../../helpers/pass/getPassSdkConfig';
import { type IState } from '../../store/types/State-type';

export async function performCreateToken(
  state: IState,
  createTokenParameters?: Omit<CreateTokenParameters, 'passId' | 'passIdType'>
): Promise<CreateTokenResponse> {
  const passSdkConfig = getPassSdkConfig({ state });

  try {
    return await createToken(passSdkConfig, {
      ...createTokenParameters,
      passId: getPassId(),
    });
  } catch (error) {
    if (error instanceof PassError) {
      // errorCode 1 means "Missing, invalid or expired P@SS identity"
      if (error.errorCode === 1) {
        // perform another createToken, but this time as prospect (without the erroneous passId param)
        return await createToken(passSdkConfig, {
          ...createTokenParameters,
        });
      }
    }

    throw error;
  }
}
