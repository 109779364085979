const lang = [
  'at',
  'cs',
  'de',
  'en',
  'fr',
  'it',
  'nl',
  'pl',
  'sk',
  'sl',
] as const;

/** All available Lang */
export type Lang = (typeof lang)[number];

/** {@link @canalplus/sdk-core!Lang} type guard */
export const isLang = (value: string): value is Lang =>
  lang.includes(value as Lang);
