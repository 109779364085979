import { PLUS_REGEX } from '../regex/constants';

/**
 * Url helper
 * --------------------
 *
 * Get value from a query string of an url
 *
 * @param url The **url** to parse
 * @param key  The **key of the query string value** to extract
 *
 * @returns The value of the query string or an empty string
 *
 * @example
 * getQsValueByKey('https://url.com?test=value&toto=test', 'test')
 * // => `value`
 */
export const getQsValueByKey = (url: string, key: string): string => {
  const regex = new RegExp(`[?&]${key}(=([^&#]*)|&|#|$)`, 'i');
  const results = regex.exec(url);

  if (!results || !results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(PLUS_REGEX, ' '));
};
