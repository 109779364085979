import {
  Service,
  type LogApiMetadata,
  type Logger,
} from '@canalplus/mycanal-logger';
import { PassError } from '../../errors/PassError';
import { getErrorStatusCode } from './getErrorStatusCode';

type LogErrorParameters = {
  api: string;
  baseLogs: Partial<LogApiMetadata>;
  error: unknown;
  logger: Logger;
};

export const logError = ({
  error,
  logger,
  baseLogs,
  api,
}: LogErrorParameters): void => {
  const errorMessage = error instanceof Error ? error.message : 'Unknown error';
  const errorStack = error instanceof Error ? error.stack : undefined;
  const statusCode = getErrorStatusCode(error);
  const errorCode = error instanceof PassError ? error.errorCode : undefined;

  logger.error(
    `PASS ${api} ${statusCode} ${errorMessage}`,
    logger.generateApiMetadata(Service.Pass, api, {
      ...baseLogs,
      errorMessage,
      statusCode,
      ...(errorStack && { errorStack }),
      ...(errorCode && { errorCode }),
    })
  );
};
