/** Match a `+` character  */
export const PLUS_REGEX = /\+/g;

/** Match a `"` character  */
export const QUOTES_REGEX = /"/g;

/** Match linefeed & carriage return characters */
export const LINEFEED_CARRIAGE_RETURN_REGEX = /\n|\r/g;

/** Match all characters except `&` */
export const ALL_EXCEPT_AMPERSAND_PATTERN = '([^&]*)';

/**
 * Match an url:
 * - With `https` or `http` protocol
 * - Without protocol
 * - Without `www`
 * - With query parameters
 *
 * https://regexr.com/39p0t
 */
export const IS_URL_REGEX =
  /(https?:\/\/)?([\w\\-])+\.{1}([a-zA-Z0-9]{2,63})([\\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/i;

/** Match any mobile user agent  */
export const MOBILE_USER_AGENT_REGEX =
  /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/;
