import Cookies from 'js-cookie';
import { getRootDomain } from '../url/getRootDomain';

/**
 * Cookie Helper
 * --------------------
 *
 * Remove a cookie
 *
 * @param name The **name** of the cookie
 *
 * @category Cookie
 */
export const removeCookie = (name: string): void => {
  const domain = getRootDomain(window.location.href);

  // do not add the domain option during unit tests (jest)
  Cookies.remove(name, domain !== 'localhost' ? { domain } : undefined);
};
