import { HodorError } from './HodorError';

/**
 * A custom `HodorInvalidPassTokenError` is thrown when a statusCode 403 is received.\
 * Usually when passToken is expired or invalid.
 */
export class HodorInvalidPassTokenError extends HodorError {
  constructor(statusCode: 403) {
    super(statusCode);

    Object.setPrototypeOf(this, HodorInvalidPassTokenError.prototype);

    this.name = 'HodorInvalidPassTokenError';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HodorInvalidPassTokenError);
    }
  }
}
