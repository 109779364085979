/**
 * URL Helper
 * --------------------
 *
 * Extract the **hostname** from the given url
 *
 * @param url The **url** to parse
 * @return Return the extracted **hostname**
 *
 * @category Url
 * @example
 * getHostname('https://url.com?test=test')
 * // => 'url.com'
 */
export const getHostname = (url: string): string | undefined => {
  if (!url) {
    return '';
  }

  let hostname: string | undefined;

  // Find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  // Find & remove port number
  hostname = hostname?.split(':')[0];

  // Find & remove "?"
  hostname = hostname?.split('?')[0];

  return hostname;
};
