import { type IUserInformation } from '@canalplus/mycanal-sdk';
import {
  type CreateTokenParameters,
  type PassUserDataEnriched,
} from '@canalplus/sdk-pass';
import { performCreateToken } from '../../services/Pass/performCreateToken';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import type { IState } from '../../store/types/State-type';
import { setDeviceIdCookie } from '../cookie/setDeviceIdCookie';
import { setSessionIdCookie } from '../cookie/setSessionIdCookie';
import { refreshProfileOneCore } from '../ifcOneCore/ifc-onecore-helpers';
import { setPassJSON } from '../pass/setPassJSON';

/**
 * - On TV, retrieves the user information from the R7 API by calling `refreshProfileOneCore`
 * - On browser, performs a full **Pass createToken** cinematic
 *   - Calls `createToken` and returns the enriched user data
 *   - Sets user Data in **window.passJSON**
 *   - Sets the session ID cookie if needed
 *   - Sets the device ID cookie if needed
 */
export const getClientSideUserInfos = async (
  state: IState,
  createTokenParameters?: Omit<CreateTokenParameters, 'passId' | 'passIdType'>
): Promise<IUserInformation | PassUserDataEnriched> => {
  const isTvDevice = displayTVModeSelector(state);

  if (isTvDevice) {
    return refreshProfileOneCore({ noCache: createTokenParameters?.noCache });
  }

  const { passId, passToken, userData } = await performCreateToken(
    state,
    createTokenParameters
  );

  setPassJSON({
    ...userData,
    passToken,
  });

  setSessionIdCookie();
  setDeviceIdCookie(userData);

  return {
    ...userData,
    passToken,
    passId,
  };
};
