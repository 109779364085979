/**
 * URL Helper
 * --------------------
 *
 * Extract the **url origin** from the given url
 *
 * @param url The **url** to parse
 * @return Return the extracted **url origin**
 *
 * @category Url
 * @example
 * getUrlOrigin('https://url.com?test=test')
 * // => 'https://url.com'
 */
export const getUrlOrigin = (url: string): string | undefined => {
  const [urlOrigin] = url.split('?');

  return urlOrigin;
};
