import type { PassUserData } from '../../types/PassUserData';
import { getBaseId } from './getBaseId';

export type GetDeviceIdCookieValueParameters = {
  previousDeviceId?: string;
  isAuthenticated?: PassUserData['isAuthenticated'];
};

/**
 * Shape: 1630000000000-1f8b2bcfff00:0:1630000000000-1f8b2bcfff00
 * or base:isAuthenticated:trackingKeyId
 */
export const getDeviceIdCookieValue = ({
  previousDeviceId,
  isAuthenticated = false,
}: GetDeviceIdCookieValueParameters): string => {
  if (!previousDeviceId) {
    return `${getBaseId()}:${isAuthenticated ? '1' : '0'}:${getBaseId()}`;
  }

  const [previousBaseId, previousIsAuthenticated, previousTrackingKeyId] =
    previousDeviceId.split(':');

  const previousIsAuthenticatedStatusBool = previousIsAuthenticated === '1';
  const shouldUpdateTrackingKeyId =
    isAuthenticated !== previousIsAuthenticatedStatusBool;

  return `${previousBaseId}:${isAuthenticated ? '1' : '0'}:${shouldUpdateTrackingKeyId ? getBaseId() : previousTrackingKeyId}`;
};
