import type { OfferLocation } from '@canalplus/sdk-core';
import { uniq } from 'es-toolkit';
import {
  LCID_AU,
  LCID_BF,
  LCID_BI,
  LCID_BJ,
  LCID_BL,
  LCID_CD,
  LCID_CF,
  LCID_CG,
  LCID_CH,
  LCID_CHDE,
  LCID_CI,
  LCID_CM,
  LCID_CV,
  LCID_DJ,
  LCID_ET,
  LCID_FR,
  LCID_GA,
  LCID_GH,
  LCID_GM,
  LCID_GN,
  LCID_GP,
  LCID_GQ,
  LCID_GW,
  LCID_IT,
  LCID_KM,
  LCID_MF,
  LCID_MG,
  LCID_ML,
  LCID_MQ,
  LCID_MR,
  LCID_NC,
  LCID_NE,
  LCID_NG,
  LCID_PF,
  LCID_PL,
  LCID_RE,
  LCID_RW,
  LCID_SL,
  LCID_SN,
  LCID_TD,
  LCID_TG,
  LCID_US,
  LCID_YT,
} from '../../constants/lang';
import type { OneDiscoveryTranslations } from '../../lang/types';
import { getPublicConfig } from '../config/config-helper';

const LANGUAGES = {
  [LCID_AU]: 'fr-fr', // Australie
  [LCID_BF]: 'fr-afr', // Burkina Faso
  [LCID_BI]: 'fr-afr', // Burundi
  [LCID_BJ]: 'fr-afr', // Bénin
  [LCID_BL]: 'fr-drom', // Saint-Barthélemy
  [LCID_CD]: 'fr-afr', // République démocratique du Congo
  [LCID_CF]: 'fr-afr', // République centrafricaine
  [LCID_CG]: 'fr-afr', // Congo
  [LCID_KM]: 'fr-fr', // Comores
  [LCID_CH]: 'fr-ch', // Suisse
  [LCID_CHDE]: 'de-ch', // Suisse alémanique
  [LCID_CI]: 'fr-afr', // Côte d’Ivoire
  [LCID_CM]: 'fr-afr', // Cameroun
  [LCID_CV]: 'fr-afr', // Cap Vert
  [LCID_DJ]: 'fr-afr', // Djibouti
  [LCID_ET]: 'en-us', // Ethiopie
  [LCID_US]: 'en-us', // Etats-Unis
  [LCID_FR]: 'fr-fr', // France
  [LCID_GA]: 'fr-afr', // Gabon
  [LCID_GH]: 'fr-afr', // Ghana
  [LCID_GM]: 'fr-afr', // Gambie
  [LCID_GN]: 'fr-afr', // Guinée Conakry
  [LCID_GP]: 'fr-drom', // Guadeloupe
  [LCID_GQ]: 'fr-afr', // Guinée Équatoriale
  [LCID_GW]: 'fr-afr', // Guinée Bissau
  [LCID_IT]: 'it-it', // Italia
  [LCID_MF]: 'fr-drom', // Saint-Martin
  [LCID_MG]: 'fr-afr', // Madagascar
  [LCID_ML]: 'fr-afr', // Mali
  [LCID_MQ]: 'fr-drom', // Martinique
  [LCID_MR]: 'fr-afr', // Mauritanie
  [LCID_NC]: 'fr-nc', // Nouvelle-Calédonie
  [LCID_NE]: 'fr-afr', // Niger
  [LCID_NG]: 'fr-afr', // Nigeria
  [LCID_PL]: 'pl-pl', // Poland
  [LCID_PF]: 'fr-drom', // French Polynesia
  [LCID_RE]: 'fr-drom', // La Réunion
  [LCID_RW]: 'fr-afr', // Rwanda
  [LCID_SL]: 'fr-afr', // Sierra Leone
  [LCID_SN]: 'fr-afr', // Sénégal
  [LCID_TD]: 'fr-afr', // Tchad
  [LCID_TG]: 'fr-afr', // Togo
  [LCID_YT]: 'fr-drom', // Mayotte
};

const localeCache = new Map<string, OneDiscoveryTranslations>();

// On the server, we pre-populate the localeCache by reading from the filesystem
// This way everything is accessible via getLocalTranslations synchronously
if (IS_SERVER) {
  const locales = uniq(Object.values(LANGUAGES));

  locales.forEach((locale: string) => {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    localeCache.set(locale, require(`../../lang/${locale}`).default);
  });
}

/**
 * Load a client locale file.
 * @param locale the locale to load
 */
export async function loadClientTranslations(locale: string): Promise<void> {
  const { default: translations } = await import(
    `../../lang/${LANGUAGES[locale] || 'fr-fr'}`
  );

  localeCache.set(locale, translations);
}

export function getLocaleWithFallback(
  locale: string
): (typeof LANGUAGES)[keyof typeof LANGUAGES] {
  const { langKey, offerLocation } = getPublicConfig().defaultLocale;
  const defaultLocale = `${langKey}-${offerLocation}`;

  return LANGUAGES[locale] || LANGUAGES[defaultLocale] || 'fr-fr';
}

/**
 * Get content language for httpEquiv
 * @returns full locale or fr-FX if the location is Metropolitan France
 */
export function getContentLanguage(
  lang: string,
  offerLocation: OfferLocation
): string {
  let fullLocale = `${lang}-${offerLocation.toUpperCase()}`;
  if (fullLocale === 'fr-FR') {
    fullLocale = 'fr-FX';
  }

  return fullLocale;
}

/**
 * Synchronously retrieve the locale file
 * @param locale the locale to load
 * @returns the locale file
 *
 * @throws On the client, throw if the needed translation file was not loaded via `loadClientTranslations`
 *
 * @see loadClientTranslations
 */
export const getLocalTranslations = (
  locale: string
): OneDiscoveryTranslations => {
  if (IS_SERVER) {
    return localeCache.get(
      getLocaleWithFallback(locale)
    ) as OneDiscoveryTranslations; // || localeCache.get(defaultLocale) || (localeCache.get('fr-fr') as OneDiscoveryTranslations)
  } else {
    const translations = localeCache.get(locale);

    if (!translations) {
      throw new Error('Expected translations to be loaded');
    }

    return translations;
  }
};
