export enum DisplayContext {
  EMBED_PLAYER = 'embed',
  IFRAME = 'iframe',
  TV = 'tv',
  WEBVIEW = 'webview',
}

export enum DisplayMode {
  FULLSCREEN = 'fullscreen',
  FULLWINDOWED = 'fullwindowed',
  MODAL = 'modal',
  WINDOWED = 'windowed',
}
