import { ALL_EXCEPT_AMPERSAND_PATTERN } from '../regex/constants';

/**
 * URL Helper
 * --------------------
 *
 * Remove queries from an url
 *
 * @param url The **url** to parse
 * @param queriesToRemove The queries to remove
 * @return Return the url without somes of these queries
 *
 * @category Url
 * @example
 * removeQueriesFromUrl('https://url.com?code=123&display=tv&state=value', ['code', 'state'])
 * // => 'https://url.com?display=tv'
 */
export const removeQueriesFromUrl = (
  url: string,
  queriesToRemove: string[]
): string => {
  if (!url) {
    return '';
  }

  const [, queriesString] = url.split('?');

  if (!queriesString) {
    return url;
  }

  const newUrl = queriesToRemove.reduce((previousUrlValue, query) => {
    const searchValue = new RegExp(
      `[?|&]${query}=${ALL_EXCEPT_AMPERSAND_PATTERN}`,
      'gi'
    );
    return previousUrlValue.replace(searchValue, '');
  }, url);

  // If we removed the first query param, we have to replace the first '&' occurence by a '?'
  if (newUrl.includes('&') && !newUrl.includes('?')) {
    return newUrl.replace('&', '?');
  }

  return newUrl;
};
