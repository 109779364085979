import type { HodorSdkConfig } from '../config/types';

type GetConfigWithFilteredFeatureTogglesParameters = {
  config: HodorSdkConfig;
  whiteListedFeatureToggles: string[];
};

/** Remove some featureToggles from a given `HodorSdkConfig` */
export const getConfigWithFilteredFeatureToggles = ({
  config,
  whiteListedFeatureToggles = [],
}: GetConfigWithFilteredFeatureTogglesParameters): HodorSdkConfig => {
  const { featureToggles = [] } = config;

  return {
    ...config,
    featureToggles: featureToggles.filter((featureToggle) =>
      whiteListedFeatureToggles.includes(featureToggle)
    ),
  };
};
