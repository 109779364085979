/**
 * Window Helper
 * --------------------
 *
 * Check whether DOM is available
 *
 * @returns `true` if DOM is avalaible
 *
 * @category Window
 */
export const isClientSide = (): boolean =>
  Boolean(typeof window !== 'undefined' && window.document?.createElement);
