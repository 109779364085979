import { addQueryParam, fillTemplate } from '@canalplus/mycanal-commons';
import { getHodorApiConfig } from './getHodorApiConfig';
import type { FetchHodorParameters } from './types';

/** Filter the config value to keep only the values that are in the drogon enum */
const getFilteredValue = (
  hodorSdkValue: string | (string | number)[],
  hodorApiEnum: (string | number)[] | undefined
): (string | number)[] | string => {
  if (!hodorApiEnum || typeof hodorSdkValue === 'string') {
    return hodorSdkValue;
  }

  return hodorSdkValue.filter((value) => hodorApiEnum.includes(value));
};

export type RequestParameters = {
  endpoint: string;
  headers: Record<string, string>;
};

/**
 * Get the computed endpoint and headers from the hodorSdkConfig\
 * and the requested hodorSdkParameters
 */
export const getRequestParameters = ({
  hodorSdkConfig,
  hodorSdkParameters = [],
  url,
}: Pick<
  FetchHodorParameters,
  'url' | 'hodorSdkConfig' | 'hodorSdkParameters'
>): RequestParameters => {
  const requestParameters = {
    endpoint: url,
    headers: {},
  };

  const hodorApiConfig = getHodorApiConfig(hodorSdkConfig);

  /** Iterate on each 'hodorSdkParameter' to compute the final **endpoint** and **headers** */
  return hodorSdkParameters.reduce(
    (accumulator, { id: hodorApiId, in: hodorApiIn, enum: hodorApiEnum }) => {
      const configValue =
        hodorApiId === 'tokenCMS'
          ? hodorApiConfig.cmsToken
          : hodorApiConfig[hodorApiId];

      if (!configValue) {
        return accumulator;
      }

      const filteredValue = getFilteredValue(configValue, hodorApiEnum);

      const stringifiedValues =
        typeof filteredValue !== 'string'
          ? filteredValue.join(',')
          : encodeURIComponent(filteredValue);

      if (!stringifiedValues) {
        return accumulator;
      }

      switch (hodorApiIn) {
        case 'parameters': {
          const queryParam = new URL(accumulator.endpoint).searchParams.get(
            hodorApiId
          );
          let fullQueryParam = stringifiedValues || queryParam || '';

          // Keep current query params from endpoint only if not already in stringifiedValues to avoid duplicate values
          if (queryParam && stringifiedValues.length > 0) {
            const queryParamList = queryParam?.split(',');
            const stringifiedValuesList = stringifiedValues.split(',');
            queryParamList.forEach((param) => {
              if (!stringifiedValuesList.includes(param)) {
                fullQueryParam = `${param},${fullQueryParam}`;
              }
            });
          }

          return {
            ...accumulator,
            endpoint: addQueryParam(
              accumulator.endpoint,
              hodorApiId,
              fullQueryParam
            ),
          };
        }

        case 'path':
          return {
            ...accumulator,
            endpoint: fillTemplate(accumulator.endpoint, [
              [hodorApiId, stringifiedValues],
            ]),
          };

        case 'header':
          return {
            ...accumulator,
            headers: {
              ...accumulator.headers,
              [hodorApiId]: stringifiedValues,
            },
          };

        default:
          break;
      }

      return accumulator;
    },
    requestParameters
  );
};
