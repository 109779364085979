import { getHostname } from './getHostname';

/**
 * URL Helper
 * --------------------
 *
 * Extract the **root domain** from the given url\
 * Handle with:
 * - parameters
 * - subdomain
 * - country code top level domain (ccTLD) *(i.e. ".me.uk")*
 *
 * @param url The **url** to parse
 * @return Return the extracted **root domain**
 *
 * @category Url
 * @example
 * getRootDomain('https://url.me.uk?test=test')
 * // => 'url.me.uk'
 */
export const getRootDomain = (url: string): string => {
  if (!url) {
    return '';
  }

  let rootDomain = getHostname(url) || '';

  const splittedRootDomain = rootDomain.split('.');
  const splittedRootDomainLength = splittedRootDomain.length;

  const hasSubdomain = splittedRootDomainLength > 2;

  const hasCCTLD =
    hasSubdomain &&
    splittedRootDomain[splittedRootDomainLength - 2]?.length === 2 &&
    splittedRootDomain[splittedRootDomainLength - 1]?.length === 2;

  if (hasSubdomain) {
    rootDomain = `${splittedRootDomain[splittedRootDomainLength - 2]}.${
      splittedRootDomain[splittedRootDomainLength - 1]
    }`;
  }

  if (hasCCTLD) {
    rootDomain = `${splittedRootDomain[splittedRootDomainLength - 3]}.${rootDomain}`;
  }

  return rootDomain;
};
