import { offerZonesList } from '@canalplus/mycanal-sdk';
import { Platform, PlatformGroup } from '@canalplus/sdk-core';
import { HodorSdkConfig } from '@canalplus/sdk-hodor';
import { ApiV2NavigationNotification } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import { ApiV2InitDataLayer } from '@dce-front/hodor-types/api/v2/me/init/definitions';
import { createSelector } from '@reduxjs/toolkit';
import { Public } from '../../../config/application/types';
import { ImageLoading } from '../../constants/image';
import { RenderSource } from '../../constants/renderSource';
import {
  isFirstLevelPage,
  OVERLAY_APPLICATION_THEMES,
} from '../../helpers/application/application-helper';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { SettingQualityD2G } from '../../templates/DownloadToGo/types';
import type { IState } from '../types/State-type';
import type { ApplicationState } from './application-types';
import { pathnameSelector } from './routing-selectors';
import {
  analyticsIdSelector,
  anonymousIdSelector,
  hasAnalyticsCollectedSelector,
  hasAnonymousTrackingSelector,
  hasUserDataCollectedSelector,
  macroEligibilitySelector,
  microEligibilitySelector,
  passTokenSelector,
  profileIdSelector,
} from './user-selectors';
import type { UserState } from './user-type';

const applicationSelector = (state: IState): ApplicationState =>
  state?.application;

export const userSelector = (state: IState): UserState => state?.user;

export const settingsSelector = createSelector(
  applicationSelector,
  (application) => application?.settings
);

export const zoneInfoSelector = createSelector(
  applicationSelector,
  (application) => application?.zoneInfo
);

export const tokenCMSSelector = createSelector(
  applicationSelector,
  (application) => application?.token
);

export const userMenuSelector = createSelector(
  applicationSelector,
  (application) => application?.userMenu || []
);

export const userAgentSelector = createSelector(
  settingsSelector,
  (settings) => settings?.userAgent
);

export const subscribeUrlLiveSelector = createSelector(
  settingsSelector,
  (settings) => settings?.subscribeUrlLive
);

export const trackingLibraryUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.trackingLibraryUrl
);

export const notificationsUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.notificationsUrl
);

export const alertingV2UrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.alertingV2Url
);

export const imageQualityPercentageSelector = createSelector(
  settingsSelector,
  (settings) => settings?.imageQualityPercentage ?? 80
);

export const imageQualityPercentageLowSelector = createSelector(
  settingsSelector,
  (settings) => settings?.imageQualityPercentageLow ?? 0
);

export const imageQualityPercentageHighSelector = createSelector(
  settingsSelector,
  (settings) => settings?.imageQualityPercentageHigh ?? 100
);

export const maxImageRatioSelector = createSelector(
  settingsSelector,
  (settings) => settings?.maxImageRatio
);

export const maxPosterRatioSelector = createSelector(
  settingsSelector,
  (settings) => settings?.maxPosterRatio
);

/** @public */
export const d2gQualitySelector = createSelector(
  settingsSelector,
  (settings) => settings?.settingQualityD2G
);

export const isMobileSelector = createSelector(
  userAgentSelector,
  (userAgent) =>
    typeof userAgent?.isMobile?.isAny === 'boolean'
      ? userAgent.isMobile.isAny
      : true
);

export const isIOsSelector = createSelector(
  userAgentSelector,
  (userAgent) => !!userAgent?.isMobile?.isIOS
);

export const isAndroidSelector = createSelector(
  userAgentSelector,
  (userAgent) => !!userAgent?.isMobile?.isAndroid
);

export const isSafariSelector = createSelector(
  userAgentSelector,
  (userAgent) => !!userAgent?.isBrowser?.isSafari
);

export const isDesktop = createSelector(
  userAgentSelector,
  (userAgent) => userAgent?.isDesktop || false
);

export const userStatusSelector = createSelector(
  settingsSelector,
  (settings) => settings?.userStatus
);

export const didomiNoticeIdSelector = createSelector(
  settingsSelector,
  (settings) => settings?.noticeId
);

export const isProspectSelector = createSelector(
  userStatusSelector,
  (userStatus) => userStatus === getPublicConfig().user_status.prospect
);

export const isSubscriberSelector = createSelector(
  userStatusSelector,
  (userStatus) => userStatus === getPublicConfig().user_status.abonne
);

export const isPlayerScriptLoadedSelector = createSelector(
  settingsSelector,
  (settings) => settings?.isPlayerScriptLoaded || false
);

export const isD2GScriptLoadedSelector = createSelector(
  settingsSelector,
  (settings) => settings?.isD2GScriptLoaded || false
);

export const hostnameSelector = createSelector(
  settingsSelector,
  (settings) => settings?.hostname || ''
);

export const appKeySelector = createSelector(
  zoneInfoSelector,
  (locale) => locale?.appKey || 'mycanal'
);

const getDefaultLocale = (): Public['defaultLocale'] =>
  getPublicConfig().defaultLocale;

export const offerZoneSelector = createSelector(
  zoneInfoSelector,
  (locale) =>
    locale?.offerZone || offerZonesList[getDefaultLocale().offerLocation]
);

export const portailIdSelector = createSelector(
  zoneInfoSelector,
  (locale) => locale?.portailId || ''
);

export const langKeySelector = createSelector(
  zoneInfoSelector,
  (locale) => locale?.lang || getDefaultLocale()?.langKey
);

export const offerLocationSelector = createSelector(
  zoneInfoSelector,
  (locale) => locale?.offerLocation || getDefaultLocale()?.offerLocation
);

export const fullLocaleSelector = createSelector(
  [langKeySelector, offerLocationSelector],
  (lang, locale) => `${lang}-${locale}`
);

export const adultSubscriptionSelector = createSelector(
  settingsSelector,
  (settings) => settings?.isAdult || false
);

export const navItemsSelector = createSelector(
  settingsSelector,
  (settings) => settings?.navItems
);

/** @public */
export const applicationHeaderSelector = createSelector(
  applicationSelector,
  (application) => application?.header
);

/** @public */
export const isInContainerSelector = createSelector(
  applicationHeaderSelector,
  (header) => header?.isInContainer || 'true'
);

export const applicationFooterSelector = createSelector(
  applicationSelector,
  (application) => application?.footer || {}
);

export const applicationFooterTreeSelector = createSelector(
  applicationFooterSelector,
  (footer) => footer?.tree || []
);

/** @public */
export const applicationGDPRSelector = createSelector(
  applicationSelector,
  (application) => application?.GDPR || {}
);

export const applicationDescriptionSelector = createSelector(
  settingsSelector,
  (settings) => settings?.description || 'No description'
);

export const downloadManagerUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.downloadManagerUrl
);

export const externalServiceActivationUrlSelector = (
  state: IState,
  tvPackID: string
): any =>
  createSelector(
    settingsSelector,
    (settings) => settings && settings[`${tvPackID}ActivationUrl`]
  )(state);

export const socialMediaSelector = createSelector(
  applicationSelector,
  (application) => application?.socialMedia || []
);

export const downloadMacOsAppUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.downloadMacOsAppUrl
);

export const hodorSdkConfigSelector = createSelector(
  applicationSelector,
  analyticsIdSelector,
  anonymousIdSelector,
  hasAnalyticsCollectedSelector,
  hasAnonymousTrackingSelector,
  passTokenSelector,
  profileIdSelector,
  macroEligibilitySelector,
  microEligibilitySelector,
  offerZoneSelector,
  offerLocationSelector,
  tokenCMSSelector,
  (
    application,
    analyticsId,
    anonymousId,
    isAnalyticsEnabled,
    isAnonymousAnalyticsEnabled,
    passToken,
    profileId,
    macros,
    micros,
    offerZone,
    offerLocation,
    cmsToken
  ): HodorSdkConfig | undefined =>
    application?.hodorSdkConfig
      ? ({
          ...application.hodorSdkConfig,
          analyticsId,
          anonymousId,
          isAnalyticsEnabled,
          isAnonymousAnalyticsEnabled,
          macros,
          micros,
          offerLocation,
          offerZone,
          passToken,
          profileId: profileId?.toString() || '0',
          cmsToken,
        } satisfies HodorSdkConfig)
      : undefined
);

/**
 * Super selectors
 */

export const requestIdSelector = createSelector(
  applicationSelector,
  (application) => application?.requestId
);

export const navigationSelector = createSelector(
  applicationSelector,
  (application) => application?.navigation || []
);

export const hasNavigationSelector = createSelector(
  navigationSelector,
  (navigation) => (navigation ? navigation.length > 0 : false)
);

export const isHeaderHiddenSelector = createSelector(
  applicationSelector,
  (application) =>
    typeof application?.header?.isHidden === 'boolean'
      ? application.header.isHidden
      : false
);

export const isFooterHiddenSelector = createSelector(
  applicationFooterSelector,
  (footer) => footer?.isHidden || false
);

export const shouldShowGDPRSelector = createSelector(
  applicationGDPRSelector,
  (GDPR) => GDPR?.shouldShowGDPR || false
);
/**
 * Feature toggles
 */
export const featAvatarV2Selector = createSelector(
  settingsSelector,
  (settings) => settings?.featAvatarV2 ?? true // by default true (need for the whoIsWatching screen)
);

export const featIdentityV5Selector = createSelector(
  settingsSelector,
  (settings) => settings?.featIdentityV5 || false
);

export const featDetailTabHighlightsSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featDetailTabHighlights || false
);

export const featDetailTabRankingSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featDetailTabRanking || false
);

export const featDisplayHeaderMenuSelector = createSelector(
  settingsSelector,
  (settings) =>
    typeof settings?.featDisplayHeaderMenu === 'boolean'
      ? settings.featDisplayHeaderMenu
      : true
);

export const featUpcomingHodorStickerSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featUpcomingHodorSticker || false
);

export const getFeatureToggleProfile = createSelector(
  settingsSelector,
  hasUserDataCollectedSelector,
  (settings, hasUserDataCollected) =>
    (settings?.featProfile || false) && hasUserDataCollected
);

export const platformSelector = createSelector(
  applicationSelector,
  (application) => application?.platform || Platform.Web
);

export const platformGroupSelector = createSelector(
  applicationSelector,
  (application) => application?.platformGroup || PlatformGroup.Web
);

export const imageLoadingSelector = createSelector(
  platformGroupSelector,
  (platformGroup) =>
    (
      [
        PlatformGroup.Orange,
        PlatformGroup.SFR,
        PlatformGroup.PlayStation,
      ] as PlatformGroup[]
    ).includes(platformGroup)
      ? ImageLoading.Eager
      : ImageLoading.Lazy
);

export const getFeatureToggleKidsProfiles = createSelector(
  settingsSelector,
  platformGroupSelector,
  (settings, platformGroup) =>
    settings?.featKidsProfiles ?? platformGroup !== PlatformGroup.Orange // by default disable on orange but enable on other devices
);

export const renderSourceSelector = createSelector(
  applicationSelector,
  (state) => state?.renderSource ?? RenderSource.CLIENT
);

export const isClientRenderSourceSelector = createSelector(
  renderSourceSelector,
  (renderSource) => renderSource === RenderSource.CLIENT
);

export const getFeatureTogglePerso = createSelector(
  settingsSelector,
  (settings) => settings?.featPerso || false
);

export const getFeatureToggleUserCentricConsent = createSelector(
  settingsSelector,
  (settings) => settings?.featUserCentricConsentMode || false
);

export const getFeatureTogglePushMood = createSelector(
  settingsSelector,
  (settings) => settings?.featPushMood || false
);

export const getFeatureToggleTVoD = createSelector(
  settingsSelector,
  (settings) => settings?.featTVoD || false
);

export const getFeatureToggleTvodUrbaQRCode = createSelector(
  settingsSelector,
  (settings) => settings?.featTvodUrbaQRCode || false
);

export const getFeatureToggleNotifications = createSelector(
  settingsSelector,
  (settings) => settings?.featNotifications || false
);

export const getFeatureToggleAlertingV2 = createSelector(
  settingsSelector,
  (settings) => settings?.featAlertingV2 || false
);

export const getFeatureTogglePositionSeconds = createSelector(
  settingsSelector,
  (settings) => settings?.featPositionSeconds || false
);

export const getFeatureToggleAds = createSelector(
  settingsSelector,
  (settings) => settings?.featPlayerAds || false
);

export const getFeatureToggleNewrelic = createSelector(
  settingsSelector,
  (settings) => settings?.featNewrelic || false
);

export const getFeatureToggleD2G = createSelector(
  settingsSelector,
  (settings) => settings?.featD2G || false
);

export const getFeatureToggleDidomi = createSelector(
  settingsSelector,
  (settings) => settings?.featDidomi || false
);

export const getFeatureToggleHdr = createSelector(
  settingsSelector,
  (settings) => settings?.featHdr || false
);

export const getFeatureToggleLowLatency = createSelector(
  settingsSelector,
  (settings) => settings?.featLowLatency || false
);

export const getFeatureToggleTrailerPreview = createSelector(
  settingsSelector,
  (settings) => settings?.featTrailerPreview || false
);

export const getFeatureTogglePromotionCover = createSelector(
  settingsSelector,
  (settings) => settings?.featPromotionCover || false
);

export const getFeatureTogglePromotionBanner = createSelector(
  settingsSelector,
  (settings) => settings?.featPromotionBanner || false
);

export const getFeatureTogglePromotionVideo = createSelector(
  settingsSelector,
  (settings) => settings?.featPromotionVideo || false
);

export const getFeatureToggleShortVideoList = createSelector(
  settingsSelector,
  (settings) => settings?.featShortVideoList || false
);

export const getFeatureToggleMinimalPlayerDebug = createSelector(
  settingsSelector,
  (settings) => settings?.featMinimalPlayerDebug || false
);

export const getFeatureToggleDetailV5Sport = createSelector(
  settingsSelector,
  (settings) => settings?.featDetailV5Sport || false
);

export const getFeatureToggleDetailTabTimeline = createSelector(
  settingsSelector,
  (settings) => settings?.featDetailTabTimeline || false
);

export const getFeatureToggleDetailTabStatistics = createSelector(
  settingsSelector,
  (settings) => settings?.featDetailTabStatistics || false
);

export const getFeatureToggleCBDeleteNSave = createSelector(
  settingsSelector,
  (settings) => settings?.featCBDeleteNSave || false
);

export const getFeatSocialNetworksFooter = createSelector(
  settingsSelector,
  (settings) => settings?.featSocialNetworksFooter || false
);

export const getFeatCreativeMediaRC = createSelector(
  settingsSelector,
  (settings) => settings?.featCreativeMediaRC || false
);

export const footerDescriptionTitleSelector = createSelector(
  settingsSelector,
  (settings) => settings?.footerDescriptionTitle
);

export const footerDescriptionSelector = createSelector(
  settingsSelector,
  (settings) => settings?.footerDescription
);

export const getFeatureExternalServicesSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featExternalServices || false
);

export const getFeatureToggleLocaleSwitcherSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featLocaleSwitcher || false
);

export const getFeatureToggleIsLowDataSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featLowData || false
);

export const getFeatureToggleDisplayPersoReco = createSelector(
  settingsSelector,
  (settings) => settings?.featDisplayPersoRecoToggle
);

// Must return true for now, it will be removed in another issue
export const isDarkModeSelector = (): boolean => true;

export const getFeatureToggleMultiLiveSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featMultiLive || false
);

export const getFeatureToggleMacOsAppAvailableSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featMacOsAppAvailable || false
);

export const getFeatureToggleLiveTvFavorites = createSelector(
  settingsSelector,
  (settings) => settings?.featLiveTvFavorites || false
);

/**
 * Special feature toggle manage by hodor configurationJson
 */
export const getFeatureToggleBlueTim = createSelector(
  settingsSelector,
  (settings) => settings?.featTimBlue || false
);

/**
 * Special feature toggle manage by hodor configurationJson
 */
export const getFeatureToggleOfferHighlightVideo = createSelector(
  settingsSelector,
  (settings) => settings?.featBrandDiscoverVideo || false
);

export const getFeatureToggleWhoIsWatching = createSelector(
  settingsSelector,
  (settings) => settings?.featWhoIsWatching || false
);

/**
 * Special feature toggle manage by hodor init
 */

export const getFeatureToggleClientPerfMeasures = createSelector(
  settingsSelector,
  (settings) => settings?.featClientPerfMeasures || false
);

export const getAccountUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.accountUrlDefault
);

export const getEditAccountUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.accountUrlPathEditAccount
);

export const getEditPasswordAccountUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.accountUrlPathEditPassword
);

export const getCancellationAccountUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.accountUrlPathCancellation
);

export const getModificationAccountUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.accountUrlPathModification
);

export const getAccountPathsSelector = createSelector(
  [
    getEditAccountUrlSelector,
    getEditPasswordAccountUrlSelector,
    getCancellationAccountUrlSelector,
    getModificationAccountUrlSelector,
  ],
  (
    editAccountUrl,
    editPasswordUrl,
    accountCancellationUrl,
    accountModificationUrl
  ) => ({
    editAccountUrl,
    editPasswordUrl,
    accountCancellationUrl,
    accountModificationUrl,
  })
);

export const blackListedChannelsSelector = createSelector(
  settingsSelector,
  (settings) => (settings?.blackListedChannels || '').split(',').map(Number)
);

export const getDarkLogoURLSelector = createSelector(
  settingsSelector,
  (settings) => settings?.logoForDarkModeUrl || ''
);

export const getLightLogoURLSelector = createSelector(
  settingsSelector,
  (settings) => settings?.logoForLightModeUrl || ''
);

export const logoSelector = createSelector(
  applicationSelector,
  (application) => application?.logo || {}
);

export const firstLevelPageListSelector = createSelector(
  applicationSelector,
  (application) => application?.firstLevelPageList || []
);

export const headerLevelPageListSelector = createSelector(
  applicationSelector,
  (application) => application?.headerLevelPageList || []
);

export const searchPageParametersSelector = createSelector(
  applicationSelector,
  (application) => application?.searchPageParameters
);

export const searchDeeplinkPageParametersSelector = createSelector(
  applicationSelector,
  (application) => application?.searchDeeplinkPageParameters
);

const pagePathnameSelector = (state: IState): string => {
  return state.page?.mainOnClick?.path || '';
};

export const isFirstLevelPageSelector = createSelector(
  [firstLevelPageListSelector, offerLocationSelector, pagePathnameSelector],
  (firstLevelPageList, offerLocation, path: string) =>
    isFirstLevelPage({ firstLevelPageList, offerLocation, path })
);

export const getNBOLiveInformations = createSelector(
  settingsSelector,
  (settings) => ({
    NBOlivePlayerButtonLabel: settings.NBOlivePlayerButtonLabel,
    NBOlivePlayerButtonUrl: settings.NBOlivePlayerButtonUrl,
    NBOlivePlayerText: settings.NBOlivePlayerText,
  })
);

export const getThemeSelector = createSelector(
  settingsSelector,
  (settings) =>
    settings?.theme || OVERLAY_APPLICATION_THEMES[getPublicConfig().overlay]
);

export const getD2gQualitySetting = createSelector(
  settingsSelector,
  (settings) => settings?.settingQualityD2G || SettingQualityD2G.default
);

export const getShortVideoIdsSelector = createSelector(
  applicationSelector,
  (application) => application?.shortVideoIds || []
);

export const kidsHomeUrlSelector = createSelector(
  settingsSelector,
  (settings): string => settings?.kidsHomeUrl || ''
);

export const featDisplayPersoRecoToggleSelector = createSelector(
  settingsSelector,
  (settings): boolean => !!settings?.featDisplayPersoRecoToggle
);

export const sharingURLBuilderSelector = createSelector(
  [pathnameSelector, hostnameSelector],
  (pathname, hostname) => (pathname ? `https://${hostname}${pathname}` : '')
);

export const applicationResizeSelector = createSelector(
  applicationSelector,
  (stateApplication) => stateApplication?.resize
);

export const getStartupNotificationsSelector = createSelector(
  applicationSelector,
  (application): ApiV2NavigationNotification[] | undefined => {
    return application?.startupNotifications;
  }
);

export const isStartupNotificationOpen = createSelector(
  applicationSelector,
  (application) => application?.isStartupNotificationOpen
);

export const slideshowNotificationsTrackingContextSelector = createSelector(
  applicationSelector,
  (application) => application?.startupNotificationsTrackingContext
);

export const slideshowNotificationsTrackingSelector = createSelector(
  applicationSelector,
  (application) => application?.startupNotificationsTracking
);

export const getWhoIsWatchingSettingsSelector = createSelector(
  applicationSelector,
  (application) => {
    return application?.whoIsWatching;
  }
);

export const initTrackingSelector = createSelector(
  applicationSelector,
  (application): ApiV2InitDataLayer | undefined => {
    return application?.initTracking?.dataLayer;
  }
);

export const shouldDisplayWhoIsWatchingSelector = createSelector(
  [applicationSelector, userSelector, getFeatureToggleWhoIsWatching],
  (application, user, featureToggleWhoIsWatching) => {
    const settingsCheck =
      !application.whoIsWatching.inSession &&
      !application.whoIsWatching.disabled;

    return (
      featureToggleWhoIsWatching &&
      user.authenticated &&
      user.reco &&
      (((!application.whoIsWatching.forceDisplay ||
        application.whoIsWatching.forceDisplay === 'auto') &&
        settingsCheck) ||
        application.whoIsWatching.forceDisplay === 'yes')
    );
  }
);
