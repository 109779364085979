export type TrackingFormatted<T extends object | undefined = object> = {
  data: T;
  level1?: any;
  name?: string;
};

/**
 * Tracking Helper
 * --------------------
 *
 * Format tracking object to be usable by the tracking script
 *
 * @param tracking The **unformatted** tracking object
 * @returns The **formatted** tracking object
 *
 * @category Tracking
 * @example
 * formatTracking({ page_title: 'title' }) // { data: { page_title: 'title' } }
 */
export const formatTracking = <T extends object | undefined>(
  tracking: T
): TrackingFormatted<T> => ({
  data: { ...tracking },
});
