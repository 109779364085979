import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { errorTemplate } from './error';

export type Immersive = {
  mainOnClick?: Routing.IOneDiscoveryOnClick;
  action?: string;
  active?: boolean;
  persoUpdated?: boolean;
  clickedElementId?: string;
  tracking?: Tracking | ApiV2PageTracking;
  uniqueId?: string;
  URLPage?: string;
};

export const initialState = {
  mainOnClick: {
    displayTemplate: '',
    displayName: '',
    path: '',
  },
  active: false,
  action: '',
  persoUpdated: false,
} satisfies Immersive as Immersive;

const immersiveSlice = createSlice({
  name: 'immersive',
  initialState,
  reducers: {
    /** Open the immersive */
    openImmersive(state, action: PayloadAction<Immersive>) {
      return {
        ...state,
        ...action.payload,
        active: true,
        uniqueId: uuidv4(),
      };
    },

    /** Close the immersive */
    closeImmersive() {
      return {
        active: false,
      };
    },

    /** Sets if some perso stuff has been modified from an immersive */
    setPersoUpdated(state, action: PayloadAction<Immersive['persoUpdated']>) {
      return {
        ...state,
        persoUpdated: action.payload,
      };
    },

    setImmersiveTracking(state, action: PayloadAction<Immersive['tracking']>) {
      return {
        ...state,
        tracking: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(errorTemplate, (state, action) => {
      const { displayTemplate, displayName, path, immersive } = action.payload;

      // Do not update immersive state if immersive mode is not active
      if (!immersive) {
        return { ...state };
      }

      return {
        ...state,
        mainOnClick: {
          displayTemplate,
          displayName,
          path,
        },
      };
    });
  },
});

export const {
  openImmersive,
  closeImmersive,
  setPersoUpdated,
  setImmersiveTracking,
} = immersiveSlice.actions;

export const immersiveReducer = immersiveSlice.reducer;

export type ImmersiveActions = ReturnType<
  (typeof immersiveSlice.actions)[keyof typeof immersiveSlice.actions]
>;
