import { LogApiMetadata, Logger, Service } from '@canalplus/mycanal-logger';
import { TimeoutError } from '@canalplus/mycanal-fetch';
import { HodorError } from '../error/HodorError';
import { FetchHodorParameters } from '../helpers/types';

const getErrorStatusCode = (error: unknown) => {
  if (error instanceof HodorError) {
    return error.statusCode;
  }

  if (error instanceof TimeoutError) {
    return 0;
  }

  return -1;
};

type LogErrorParameters = {
  api: FetchHodorParameters['api'];
  baseLogs: Partial<LogApiMetadata>;
  error: unknown;
  logger: Logger;
};

export const logError = ({
  error,
  logger,
  baseLogs,
  api,
}: LogErrorParameters): void => {
  const errorMessage =
    error instanceof Error ||
    error instanceof HodorError ||
    error instanceof TimeoutError
      ? error.message
      : 'Unknown error';

  const errorStack =
    error instanceof Error ||
    error instanceof HodorError ||
    error instanceof TimeoutError
      ? error.stack
      : undefined;

  const statusCode = getErrorStatusCode(error);

  logger.error(
    `HODOR ${api} ${statusCode} ${errorMessage}`,
    logger.generateApiMetadata(Service.Hodor, api, {
      ...baseLogs,
      errorMessage,
      statusCode,
      ...(errorStack && { errorStack }),
    })
  );
};
