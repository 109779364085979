/**
 * Function Helper
 * --------------------
 *
 * Generic enum type guard.
 * Used to test if a token is part of an enum
 *
 * @param someEnum The **enum** to be parsed
 * @param token The **token** to test
 *
 * @category Function
 */
export const isSomeEnum =
  <T extends { [s: string]: unknown }>(someEnum: T) =>
  (token: unknown): token is T[keyof T] =>
    Object.values(someEnum).includes(token as T[keyof T]);
