/**
 * Return `Object.keys` with better typings
 *
 * @example
 * const obj = {
 *  a: 1,
 *  b: 2
 * }
 *
 * const test1 = Object.keys(obj) // returnType = string[]
 * const test2 = getObjectKeys(obj) // returnType = ("a" | "b")[]
 */
export const getObjectKeys = <Obj extends Record<string, unknown>>(
  obj: Obj
): (keyof Obj)[] => Object.keys(obj) as (keyof Obj)[];
