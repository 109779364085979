/**
 * Getting Literal Template Strings
 * @param	{string} template  String to be parsed
 * @param	{object} data      Object containing Key -> Value pairs.
 * @param	{regexp} regexp    regexp to capture dynamic variables
 * @returns {string}	Formatted string
 */
export function parseTemplate(template, data, regexp = /\${(\w+)}/g) {
  function replaceFn(...args) {
    const prop = args[1];
    return prop in data ? data[prop] : '';
  }
  return template.replace(regexp, replaceFn);
}
