import { IS_URL_REGEX } from '../regex/constants';

/**
 * URL Helper
 * --------------------
 *
 * Check if the given **string** parameter is an url or not
 *
 * @param url The **string** to parse
 * @return Return `true` if the string parameter is an url
 *
 * @category Url
 * @example
 * isUrl('https://url.com')
 * // => true
 */
export const isUrl = (url: string): boolean => Boolean(url.match(IS_URL_REGEX));
